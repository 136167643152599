.block-social {
  .social-links {
    margin-top: 15px;
    margin-left: -5px;
    margin-right: -5px;
  }
  li {
    height: 40px;
    width: 40px;
    display: inline-block;
    background-color: #2d2d2d;
    margin: 0 5px 10px;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 80%;
    transition: all 0.3s;
    &:hover {
      border-radius: 50%;
    }
    a {
      display: block;
      height: 100%;
      white-space: nowrap;
      text-indent: 100%;
      overflow: hidden;
      &:hover {
        color: transparent;
      }
    }
  }
  .facebook {
    background-image: url(../img/facebook.svg);
    background-color: #3b5998;
  }
  .twitter {
    background-image: url(../img/twitter.svg);
    background-color: #55acee;
  }
  .rss {
    background-image: url(../img/rss.svg);
    background-color: #ff6600;
  }
  .youtube {
    background-image: url(../img/youtube.svg);
    background-color: #bb0000;
  }
  .googleplus {
    background-image: url(../img/gplus.svg);
    background-color: #dd4b39;
  }
  .pinterest {
    background-image: url(../img/pinterest.svg);
    background-color: #cb2027;
  }
  .vimeo {
    background-image: url(../img/vimeo.svg);
    background-color: #aad450;
  }
  .instagram {
    background-image: url(../img/instagram.svg);
    background-color: #125688;
  }
}
